<template>
  <div id="app" ref="appDiv" class="body-m">
    <slot />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const route = useRoute()

const appDiv = ref<HTMLDivElement>()

const resizeObserver = ref<ResizeObserver>()

onMounted(() => {
  if (window.self !== window.top && appDiv.value) {
    window.parent.postMessage(
      {
        height: document.body.offsetHeight,
      },
      '*'
    )
    resizeObserver.value = new ResizeObserver(() => {
      window.parent.postMessage(
        {
          height: document.body.offsetHeight,
        },
        '*'
      )
    })

    resizeObserver.value.observe(appDiv.value)
  }
})

onBeforeUnmount(() => {
  if (resizeObserver.value && appDiv.value) {
    resizeObserver.value.unobserve(appDiv.value)
  }
})

useHead(() => {
  // Have to use config.public.site here, due to composables not working in useHead
  const siteConfig = config.public.site

  return {
    link: [
      {
        rel: 'canonical',
        href: siteConfig.url + route.path,
      },
    ],
  }
})
</script>

<style lang="scss">
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  @apply bg-white font-serif text-black h-full;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

@media print {
  .no-print {
    display: none !important;
  }
}
</style>
